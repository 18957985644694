import React from "react"

interface Props {}

export default function Schedule({}: Props) {
  return (
    <div className="flex justify-center items-center h-full">
      <iframe
        src="https://schedule.cience.com/team/cience-customer-success/call?theme=light"
        style={{ border: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  )
}
