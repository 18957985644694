import React from "react"
import cx from "clsx"

function Divider({ extraStyle, extraClass }: any) {
  return (
    <span
      className={cx("w-full border-b border-newWhite dark:border-darkColor-900 text-sm", extraClass)}
      style={extraStyle}
    />
  )
}

export default Divider
