declare global {
  interface Window {
    Appcues: any;
  }
}

async function getOrgId(projectName: string) {
  const baseUrl = "https://org-switcher.cience.com"
  try {
    const response = await fetch(`${baseUrl}/api/v1/organizations/current`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ flow_project_name: projectName }),
    })

    if (response.status !== 200) {
      console.error(`Error while fetching OrgId: Received status code ${response.status}`)
    }

    const { id } = await response.json()
    return id
  } catch (error) {
    console.error("Error while fetching OrgId:", error)
    return null
  }
}

export function appcuesLogout() {
  if (typeof window !== 'undefined' && window.Appcues) {
    window.Appcues.reset();
  }
}

/** Register the organization to appcues via call identify */
export function appcuesIdentify(userId: string, userEmail: string, projectName: string) {
  if (typeof window !== 'undefined' && window.Appcues && userId && projectName) {
    console.log("UserID:: ", userId);
    console.log("ProjectName:: ", projectName)
    getOrgId(projectName).then((orgId) => {
      if (!orgId) {
        console.error("Error while fetching OrgId: Organization not found")
        return
      }
      const appcuesUserId = orgId + '_' + userId;
      window.Appcues.identify(appcuesUserId, {
        orgId: orgId,
        isAdmin: false,
        email: userEmail,
      });
    })
  }
}