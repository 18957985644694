import React, { useEffect } from 'react';

const Feedback = () => {
  useEffect(() => {
    // Function to dynamically load the Featurebase SDK
    const loadFeaturebaseSDK = () => {
      const sdkId = 'featurebase-sdk';
      if (document.getElementById(sdkId)) {
        // SDK already loaded
        return;
      }
      const script = document.createElement('script');
      script.id = sdkId;
      script.src = 'https://do.featurebase.app/js/sdk.js';
      script.onload = () => {
        // Initialize Featurebase after loading
        window.Featurebase('embed', {
          organization: 'cience',
          theme: 'light',
          initialPage: 'Board',
          hideMenu: true,
          hideLogo: false,
        });
      };
      document.getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0]);
    };

    // Load the SDK either immediately or after DOM is ready
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      loadFeaturebaseSDK();
    } else {
      document.addEventListener('DOMContentLoaded', loadFeaturebaseSDK);
    }

    // Cleanup function to remove the SDK script when the component unmounts
    return () => {
      const sdkScript = document.getElementById('featurebase-sdk');
      if (sdkScript) {
        sdkScript.parentNode.removeChild(sdkScript);
      }
    };
  }, []);

  return (
    <div className="overflow-auto">
      <div data-featurebase-embed></div>
    </div>
  );
};

export default Feedback;
