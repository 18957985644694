export default async function AvailableLinks(organizationId: any) {
  const baseUrl = "https://org-switcher.cience.com";
  const response = await fetch(`${baseUrl}/api/v1/links`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ organization_id: organizationId || null }),
  });

  const { apps } = await response.json();
  return apps;
}
