const currentApp = "goFlow";

export default function LinkBuilder(navObject: any[], availableLinks: any[]) {
  return navObject.reduce(function (newArr: any[], link: { id: any }) {
    availableLinks.forEach((d: any) => {
      const isCurrentApp = link.id === currentApp;

      if (d.name === link.id) {
        const createLink = {
          ...link,
          href: isCurrentApp ? "/" : d.link,
          currentApp: isCurrentApp,
        };
        newArr.push(createLink);
      }
    });
    return newArr;
  }, []);
}
