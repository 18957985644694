/**
 * Displays the 'connection' card - source, or destination or API key
 */

import React, { ReactElement, ReactNode } from "react"
import styles from "./ConnectionCard.module.less"
import { EditableName } from "../EditableName/EditableName"
import { Badge, Button, Dropdown, Menu, Tooltip } from "antd"
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined"
import { NavLink } from "react-router-dom"
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined"
import CodeOutlined from "@ant-design/icons/lib/icons/CodeOutlined"
import SubMenu from "antd/lib/menu/SubMenu"
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined"
import * as logos from "assets/logos"

/**
 * Action - link or onClick handler
 */
export type ConnectionCardAction = string | (() => void) | undefined

/**
 * Returns link (if action is string, meaning URL), or Button
 */
function ActionLink({
  action,
  children,
  ...rest
}: {
  action: ConnectionCardAction
  children: ReactNode
  [key: string]: any
}) {
  if (!action) {
    return <span {...rest}>{children}</span>
  } else if (typeof action === "string") {
    return (
      <NavLink to={action} {...rest} style={{ color: "unset" }}>
        {children}
      </NavLink>
    )
  } else {
    return (
      <a className="text-text" onClick={action} {...rest}>
        {children}
      </a>
    )
  }
}

export type ConnectionCardProps = {
  //icon of connection
  icon: ReactNode
  disabled: boolean

  deleteAction: ConnectionCardAction
  editAction: ConnectionCardAction
  menuOverlay: ReactElement | undefined

  title: string
  rename: (newName: string) => Promise<any>

  subtitle: ReactNode

  status: ReactNode

  //If connection card is loading - we're waiting something from the
  //server which changes the state of the card
  loading?: boolean
}

export function ConnectionCard(props: ConnectionCardProps) {
  console.log({ props })
  return (
    <div className={`${styles.connectionCard} ${props.loading && styles.connectionCardLoading}`}>
      <div className="w-8 h-8 mr-4">{props.icon}</div>
      <div className="flex-1">
        <ActionLink action={props.editAction}>
          <span className="text-link">{props.title}</span>
        </ActionLink>
      </div>
      <div className="text-secondaryText hidden">{props.subtitle}</div>

      {props.status}

      <div className="w-8 ml-6 right-8 flex align-center">
        {props.menuOverlay && (
          <Dropdown trigger={["click"]} overlay={props.menuOverlay}>
            <div className="text-lg px-3 hover:bg-bgComponent cursor-pointer rounded-full text-center">⋮</div>
          </Dropdown>
        )}
      </div>
    </div>
  )
}
