const navIconSize = {
  width: "24px",
  height: "24px",
  verticalAlign: "middle",
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const GoIcons = ({ name }) => {
  const icons = {
    goShow: (
      <svg
        style={navIconSize}
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00001 1C11.8701 1 15 5.08334 15 5.08334C15 5.08334 11.8652 9.16667 8.00001 9.16667C4.13477 9.16667 1 5.08334 1 5.08334C1 5.08334 4.12993 1 8.00001 1Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="7.99999" cy="5.08333" r="2.33333" stroke="#3076FF" />
      </svg>
    ),
    goFlow: (
      <svg
        style={navIconSize}
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7826 4.2111L7.49894 8.49475C6.01222 9.98147 3.60176 9.98147 2.11504 8.49475C0.62832 7.00803 0.628319 4.59757 2.11504 3.11085L3.96051 1.26539M11.7826 4.2111L11.7826 6.27661M11.7826 4.2111L9.71709 4.21111M4.21742 6.39869L8.50106 2.11504C9.98779 0.628319 12.3982 0.62832 13.885 2.11504C15.3717 3.60176 15.3717 6.01222 13.885 7.49894L12.0395 9.3444M4.21742 6.39869L4.2174 4.33312M4.21742 6.39869L6.28291 6.39862"
          stroke="#3076FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    goDigital: (
      <svg
        style={navIconSize}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 13V3C1 1.9 1.9 1 3 1H13C14.1 1 15 1.9 15 3V13C15 14.1 14.1 15 13 15H3C1.9 15 1 14.1 1 13Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.24045 8.55379C3.24045 8.11112 3.33111 7.71912 3.51245 7.37779C3.69911 7.03645 3.94978 6.77245 4.26445 6.58579C4.58445 6.39379 4.93645 6.29779 5.32045 6.29779C5.66711 6.29779 5.96845 6.36712 6.22445 6.50579C6.48578 6.63912 6.69378 6.80712 6.84845 7.00979V6.36979H7.76845V10.7778H6.84845V10.1218C6.69378 10.3298 6.48311 10.5031 6.21645 10.6418C5.94978 10.7805 5.64578 10.8498 5.30445 10.8498C4.92578 10.8498 4.57911 10.7538 4.26445 10.5618C3.94978 10.3645 3.69911 10.0925 3.51245 9.74579C3.33111 9.39379 3.24045 8.99645 3.24045 8.55379ZM6.84845 8.56979C6.84845 8.26579 6.78445 8.00179 6.65645 7.77779C6.53378 7.55379 6.37111 7.38312 6.16845 7.26579C5.96578 7.14845 5.74711 7.08979 5.51245 7.08979C5.27778 7.08979 5.05911 7.14845 4.85645 7.26579C4.65378 7.37779 4.48845 7.54579 4.36045 7.76979C4.23778 7.98845 4.17645 8.24979 4.17645 8.55379C4.17645 8.85779 4.23778 9.12445 4.36045 9.35379C4.48845 9.58312 4.65378 9.75912 4.85645 9.88179C5.06445 9.99912 5.28311 10.0578 5.51245 10.0578C5.74711 10.0578 5.96578 9.99912 6.16845 9.88179C6.37111 9.76445 6.53378 9.59379 6.65645 9.36979C6.78445 9.14045 6.84845 8.87379 6.84845 8.56979Z"
          fill="#3076FF"
        />
        <path
          d="M8.26232 8.55379C8.26232 8.11112 8.35299 7.71912 8.53432 7.37779C8.72099 7.03645 8.97166 6.77245 9.28632 6.58579C9.60632 6.39379 9.96099 6.29779 10.3503 6.29779C10.6383 6.29779 10.921 6.36179 11.1983 6.48979C11.481 6.61245 11.705 6.77779 11.8703 6.98579V4.85779H12.7903V10.7778H11.8703V10.1138C11.721 10.3271 11.513 10.5031 11.2463 10.6418C10.985 10.7805 10.6837 10.8498 10.3423 10.8498C9.95832 10.8498 9.60632 10.7538 9.28632 10.5618C8.97166 10.3645 8.72099 10.0925 8.53432 9.74579C8.35299 9.39379 8.26232 8.99645 8.26232 8.55379ZM11.8703 8.56979C11.8703 8.26579 11.8063 8.00179 11.6783 7.77779C11.5557 7.55379 11.393 7.38312 11.1903 7.26579C10.9877 7.14845 10.769 7.08979 10.5343 7.08979C10.2997 7.08979 10.081 7.14845 9.87832 7.26579C9.67566 7.37779 9.51032 7.54579 9.38232 7.76979C9.25966 7.98845 9.19832 8.24979 9.19832 8.55379C9.19832 8.85779 9.25966 9.12445 9.38232 9.35379C9.51032 9.58312 9.67566 9.75912 9.87832 9.88179C10.0863 9.99912 10.305 10.0578 10.5343 10.0578C10.769 10.0578 10.9877 9.99912 11.1903 9.88179C11.393 9.76445 11.5557 9.59379 11.6783 9.36979C11.8063 9.14045 11.8703 8.87379 11.8703 8.56979Z"
          fill="#3076FF"
        />
      </svg>
    ),
    goData: (
      <svg
        style={navIconSize}
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.39567 1.00002C4.83712 1.00002 4.29113 1.16563 3.82672 1.47594C3.3623 1.78625 3.00035 2.22731 2.78661 2.74334C2.57286 3.25936 2.51692 3.8272 2.62588 4.37501C2.73485 4.92282 3.00384 5.42602 3.39879 5.82097C3.79374 6.21592 4.29694 6.48488 4.84475 6.59384C5.39256 6.70281 5.96036 6.6469 6.47639 6.43315C6.99242 6.21941 7.43348 5.85742 7.74379 5.39301C8.0541 4.9286 8.21974 4.3826 8.21974 3.82406C8.22128 3.45277 8.14927 3.08485 8.00789 2.74152C7.86652 2.39819 7.65858 2.08627 7.39603 1.82373C7.13349 1.56118 6.82153 1.35321 6.47821 1.21183C6.13488 1.07046 5.76696 0.998481 5.39567 1.00002V1.00002Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.41191 12.3591C9.47058 12.3595 9.52863 12.3476 9.58236 12.324C9.63608 12.3004 9.68426 12.2658 9.72372 12.2224C9.76317 12.179 9.79303 12.1278 9.81136 12.072C9.82969 12.0163 9.83607 11.9573 9.83008 11.899C9.72164 10.8036 9.20979 9.78769 8.39402 9.0487C7.57825 8.30971 6.51685 7.90044 5.41613 7.90044C4.31541 7.90044 3.25401 8.30971 2.43824 9.0487C1.62247 9.78769 1.11062 10.8036 1.00218 11.899C0.996195 11.9573 1.00257 12.0163 1.0209 12.072C1.03923 12.1278 1.06909 12.179 1.10854 12.2224C1.148 12.2658 1.19614 12.3004 1.24987 12.324C1.30359 12.3476 1.36169 12.3595 1.42035 12.3591H9.41191Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.72626 11.2714H14.5794C14.6392 11.2693 14.6979 11.255 14.7519 11.2293C14.8058 11.2035 14.8539 11.1669 14.8932 11.1218C14.9324 11.0767 14.9619 11.0239 14.9799 10.9669C14.9978 10.9099 15.0039 10.8497 14.9976 10.7903C14.9023 10.0675 14.5931 9.3896 14.1097 8.84376C13.6264 8.29792 12.991 7.90901 12.285 7.72691C11.5791 7.5448 10.8348 7.57783 10.1477 7.82178C9.46066 8.06572 8.8622 8.50942 8.42914 9.09594"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5454 2.2971C11.1276 2.2971 10.7191 2.421 10.3717 2.65312C10.0243 2.88524 9.75356 3.2152 9.59367 3.60121C9.43378 3.98721 9.39194 4.41194 9.47345 4.82172C9.55496 5.23151 9.75616 5.60793 10.0516 5.90337C10.347 6.19881 10.7235 6.40001 11.1333 6.48152C11.543 6.56303 11.9678 6.5212 12.3538 6.36131C12.7398 6.20142 13.0697 5.93064 13.3019 5.58324C13.534 5.23584 13.6579 4.82742 13.6579 4.4096C13.6586 4.13197 13.6045 3.85693 13.4986 3.60028C13.3927 3.34363 13.2372 3.11044 13.0408 2.91413C12.8445 2.71781 12.6113 2.56226 12.3547 2.45637C12.098 2.35048 11.823 2.29633 11.5454 2.2971V2.2971Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    goChat: (
      <svg
        style={navIconSize}
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.3329 10.9334C14.7612 10.4024 14.9964 9.74167 15 9.05946C15 7.1391 13.126 5.58722 10.803 5.58722C8.49177 5.58722 6.60608 7.13961 6.60608 9.05946C6.60608 10.9793 8.48005 12.5317 10.803 12.5317C11.5101 12.5357 12.2092 12.3825 12.8498 12.0832L14.7701 12.6464L14.3329 10.9334Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7179 5.43396C11.7179 2.98203 9.30983 1 6.35896 1C3.39534 1 1 2.98203 1 5.43396C1.00393 6.3059 1.30424 7.15059 1.85162 7.82932L1.29407 10.0147L3.74599 9.29506C4.56393 9.67735 5.45662 9.87303 6.35947 9.86792"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    goSchedule: (
      <svg
        style={navIconSize}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5005 15.0519H2.49893C2.3018 15.0521 2.10658 15.0133 1.92446 14.9379C1.74233 14.8625 1.57689 14.7518 1.43763 14.6123C1.29837 14.4728 1.18802 14.3071 1.11292 14.1249C1.03783 13.9426 0.999453 13.7473 1.00001 13.5502V4.06777C0.999822 3.87088 1.03847 3.6759 1.11373 3.49396C1.189 3.31202 1.29941 3.14668 1.43863 3.00745C1.57786 2.86823 1.74316 2.75784 1.9251 2.68258C2.10704 2.60731 2.30203 2.56866 2.49893 2.56885H13.5005C13.6974 2.56866 13.8924 2.60731 14.0744 2.68258C14.2563 2.75784 14.4216 2.86823 14.5608 3.00745C14.7001 3.14668 14.8105 3.31202 14.8857 3.49396C14.961 3.6759 14.9996 3.87088 14.9995 4.06777V13.5502C15 13.7473 14.9616 13.9426 14.8865 14.1249C14.8114 14.3071 14.7011 14.4728 14.5618 14.6123C14.4226 14.7518 14.2571 14.8625 14.075 14.9379C13.8929 15.0133 13.6977 15.0521 13.5005 15.0519V15.0519Z"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.07129 6.44824H15.0001"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.01355 1V4.24404"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1611 1V4.24404"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66638 10.017L7.26945 11.4512L10.4417 8.58276"
          stroke="#3076FF"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
      goOutbound: (
          <svg
              style={navIconSize}
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.9999 6.54781C14.9999 6.56648 14.9999 6.58782 14.9973 6.60649C14.9679 7.51618 14.221 8.24446 13.3033 8.24446C13.2606 8.24446 13.2232 8.24179 13.1805 8.23912V4.85381C13.2232 4.85114 13.2606 4.84849 13.3033 4.84849C14.2396 4.85116 14.9999 5.61145 14.9999 6.54781Z"
                  stroke="#3076FF"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
              />
              <path
                  d="M13.1808 1.20465V12.481C13.1808 12.6891 12.906 12.7611 12.8046 12.5824C11.1747 9.80798 8.84579 8.98098 6.63693 8.43677V5.24354C8.84579 4.702 11.1747 3.87501 12.8046 1.1006C12.9087 0.921866 13.1808 0.996565 13.1808 1.20465Z"
                  stroke="#3076FF"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
              />
              <path
                  d="M6.63685 4.97132V8.7888H1.72561C1.32545 8.7888 1 8.46334 1 8.06052V5.69694C1 5.29679 1.32545 4.97132 1.72561 4.97132H6.63685Z"
                  stroke="#3076FF"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
              />
              <path
                  d="M4.45421 8.7915H2.81891V11.9754C2.81891 12.427 3.18498 12.793 3.63656 12.793C4.08813 12.793 4.45421 12.427 4.45421 11.9754V8.7915Z"
                  stroke="#3076FF"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
              />
          </svg>
      ),
      goAi: (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="10.2857" cy="3.14286" r="2.14286" stroke="#3076FF" strokeLinejoin="round" />
          <circle cx="3.14286" cy="16" r="2.14286" stroke="#3076FF" strokeLinejoin="round" />
          <path d="M3.88657 18.0272C5.54304 19.8533 7.93489 21.0001 10.5946 21.0001C12.2473 21.0001 13.7965 20.5573 15.1302 19.7839M15.3556 4.24338C17.9323 5.83942 19.6486 8.69232 19.6486 11.9461C19.6486 12.6526 19.5677 13.3403 19.4146 14.0002M1.57849 11.1115C1.92373 7.33389 4.58946 4.22727 8.13807 3.22925" stroke="#3076FF" strokeLinejoin="round" />
          <path d="M13.6498 9V14.3318" stroke="#3076FF" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.0088 14.3318L9.54799 9L7.08716 14.3318" stroke="#3076FF" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.90759 12.6909H11.1887" stroke="#3076FF" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="18.8571" cy="16" r="2.14286" stroke="#3076FF" strokeLinejoin="round" />
        </svg>
      ),
      goEngage: (
        <svg style={navIconSize} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_5148_38649)">
            <path d="M2.42538 4.02725C3.31006 4.02725 4.02724 3.31007 4.02724 2.42539C4.02724 1.54071 3.31006 0.823528 2.42538 0.823528C1.5407 0.823528 0.823517 1.54071 0.823517 2.42539C0.823517 3.31007 1.5407 4.02725 2.42538 4.02725Z" stroke="#3076FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6117 15.6471C12.4964 15.6471 13.2135 14.9299 13.2135 14.0453C13.2135 13.1606 12.4964 12.4435 11.6117 12.4435C10.727 12.4435 10.0098 13.1606 10.0098 14.0453C10.0098 14.9299 10.727 15.6471 11.6117 15.6471Z" stroke="#3076FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.91975 14.2997H5.87694C3.99086 14.2997 2.17102 12.5951 2.17102 10.4926V4.19264" stroke="#3076FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.2461 0.823545C8.77965 0.823545 8.32375 0.96184 7.93591 1.22097C7.54809 1.4801 7.24584 1.84843 7.06734 2.27936C6.88885 2.71028 6.84213 3.18446 6.93313 3.64193C7.02412 4.0994 7.24874 4.51961 7.57856 4.84942C7.90837 5.17923 8.3286 5.40383 8.78608 5.49483C9.24355 5.58583 9.71765 5.53913 10.1486 5.36064C10.5795 5.18215 10.9478 4.87986 11.2069 4.49204C11.4661 4.10422 11.6044 3.64827 11.6044 3.18184C11.6057 2.87179 11.5456 2.56454 11.4275 2.27783C11.3095 1.99113 11.1358 1.73065 10.9165 1.51141C10.6973 1.29216 10.4368 1.11848 10.1501 1.00042C9.86342 0.882369 9.55616 0.82226 9.2461 0.823545Z" stroke="#3076FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5994 10.2568C12.6487 10.2571 12.6973 10.2481 12.7425 10.2303C12.7875 10.2125 12.8279 10.1863 12.861 10.1535C12.8942 10.1207 12.9192 10.082 12.9346 10.0399C12.95 9.99781 12.9553 9.95318 12.9503 9.90912C12.8593 9.08147 12.4298 8.31386 11.7452 7.75543C11.0606 7.19705 10.1699 6.88781 9.24625 6.88781C8.32249 6.88781 7.43183 7.19705 6.74725 7.75543C6.06268 8.31386 5.63314 9.08147 5.54214 9.90912C5.53712 9.95318 5.54247 9.99781 5.55786 10.0399C5.57323 10.082 5.59829 10.1207 5.6314 10.1535C5.66451 10.1863 5.70491 10.2125 5.75 10.2303C5.79508 10.2481 5.84383 10.2571 5.89306 10.2568H12.5994Z" stroke="#3076FF" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_5148_38649">
                <rect width="14" height="16.4706" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      ),
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <>{icons[name]}</>;
};

export default GoIcons;
