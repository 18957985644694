export default async function getAvailableOrganizations(userId: string) {
  const baseUrl = "https://org-switcher.cience.com";
  const response = await fetch(`${baseUrl}/api/v1/organizations/${userId}`, {
    method: "get",
    headers: {
      Accept: "application/json",
    },
  });

  const { organizations } = await response.json();
  // if (!organizations.length || organizations.length > 1) return;
  if (!organizations.length) return;
  if (organizations.length > 1) return "multiple";
  return organizations[0]?.id;
}
