export const navLinks = [
  {
    name: "GO Data",
    id: "goData",
  },
  {
    name: "GO Flow",
    id: "goFlow",
  },
  {
    name: "GO Show",
    id: "goShow",
  },
  {
    name: "GO Schedule",
    id: "goSchedule",
  },
  {
    name: "GO Digital",
    id: "goDigital",
  },
  {
    name: "GO Chat",
    id: "goChat",
  },
  {
    name: "GO Outbound",
    id: "goOutbound",
  },
  {
    name: "GO AI",
    id: "goAi",
  },  
  {
    name: "GO Engage",
    id: "goEngage",
  }
];
