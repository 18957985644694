import goChatSnippetTemplate from "./goChatSnippetTemplate"

export default async function goChatSnippet(email: string, name: string) {
  const baseUrl = "https://org-switcher.cience.com"
  try {
    const response = await fetch(`${baseUrl}/api/v1/jwe_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, name: name }),
    })

    if (response.status !== 200) {
      console.error(`Error while fetching jwe_data: Received status code ${response.status}`)
      goChatSnippetTemplate("YOUR_ENCRYPTED_JWE_DATA")
    }

    const { jwe_data } = await response.json()
    const snippet = document.getElementById("goChat")
    if (!snippet) {
      goChatSnippetTemplate(jwe_data)
    }
  } catch (error) {
    console.error("Error while fetching jwe_data:", error)
    goChatSnippetTemplate("YOUR_ENCRYPTED_JWE_DATA")
  }
}
