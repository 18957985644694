import React, {useState} from "react";
import {Button, Dropdown} from "antd";
import gridIcon from "../../../icons/grid-icon.png";
import GoIcons from 'ui/components/goPlatform/goPlatformIcons';



export const TransitionInOtherApps: React.FC<{hideMenu: () => void, }> = (props: any) => {
    const menuLinks = props.appNav

    const appNavStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridColumnGap: "0px",
        gridRowGap: "10px",
        width: "370px"
    };

    return (
        <div>
            <div className={`px-5 py-1.5 border-b border-main`} style={appNavStyle}>
                {menuLinks.map((o) => (
                    <a
                        href={o.href}
                        key={o.href}
                        className={`flex align-middle items-center py-2 `}
                    >
                        <span className={'mr-2'}>{<GoIcons name={o.id}/>}</span>
                        <span
                            style={{
                                color: `${o.currentApp ? "#2668eb" : "#000"}`
                            }}
                            className={`text-textPale text-sm font-thin`}
                        >{o.name}</span>
                    </a>
                ))}
            </div>
        </div>
    )
}

function AppNavMenu(appNav: any) {
    const [dropdownVisible, setDropdownVisible] = useState(false)

    return (
        <Dropdown
            key={"userMenuDropdown"}
            trigger={["click"]}
            visible={dropdownVisible}
            overlay={<TransitionInOtherApps {...appNav} hideMenu={() => setDropdownVisible(false)} />}
            onVisibleChange={vis => setDropdownVisible(vis)}
        >
            <Button
                size="large"
                shape="circle"
                style={{border:'transparent'}}
            >
                <img src={gridIcon} alt="grid" className="h-5" />
            </Button>
        </Dropdown>
    )
}

export default AppNavMenu;