import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import cx from "clsx"

import Divider from "./Divider"
import { menuItems } from "./constants"
import { ReactComponent as IconLogout } from "icons/menu/logout.svg"
import ProjectLink from "lib/components/ProjectLink/ProjectLink"

export default function NavMenu({ onLogout, onAccount }: any) {
  const generateLink = (item, linkTo, href, isEmail, onClick) => {
    if (onClick) {
      return (
        <a href="#" className="bg-transparent focus:outline-none" onClick={onClick}>
          {item}
        </a>
      )
    }
    if (linkTo) {
      return (
        <ProjectLink to={linkTo} stripLink={isEmail}>
          {item}
        </ProjectLink>
      )
    }

    return <a href={href}>{item}</a>
  }

  return (
    <Menu>
      <Transition
        show={true}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="bg-white absolute z-50 right-0 flex flex-col py-2 w-72 mt-2 origin-top-right dark:bg-darkColor-900 rounded-md gap-2 shadow-lg"
          style={{ background: "white" }}
        >
          {menuItems({ onAccount }).map(
            ({ id, href, linkTo, title, icon: Icon, isDivider, isDisabled, isEmail, onClick }, index) => {
              const item = (
                <span className="flex gap-4">
                  <Icon />
                  <span
                    className={cx(
                      "text-sm font-inter",

                      isDisabled ? "text-gray-300" : "text-black"
                    )}
                  >
                    {title}
                  </span>
                </span>
              )
              return (
                <Fragment key={index}>
                  <Menu.Item
                    as="span"
                    className={cx(
                      "py-2 px-6 rounded",
                      isDisabled ? "cursor-default " : "hover:bg-gray-100 dark:hover:bg-darkColor"
                    )}
                  >
                    {!isDisabled ? <span>{generateLink(item, linkTo, href, isEmail, onClick)}</span> : item}
                  </Menu.Item>
                  {isDivider && <Divider />}
                </Fragment>
              )
            }
          )}
          <Menu.Item key={"logout"} as="span" className="flex gap-4 py-2 px-6 rounded hover:bg-light">
            <IconLogout />
            <a href="#" className="text-black text-sm font-inter" onClick={onLogout}>
              Logout
            </a>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
