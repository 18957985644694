import { ReactComponent as IconAccount } from "icons/menu/account.svg"
import { ReactComponent as IconBookCall } from "icons/menu/book_call.svg"
import { ReactComponent as IconChangelog } from "icons/menu/changelog.svg"
import { ReactComponent as IconContactSupport } from "icons/menu/contact_support.svg"
import { ReactComponent as IconFeedback } from "icons/menu/feedback.svg"
import { ReactComponent as IconHelpCenter } from "icons/menu/help_center.svg"
import { ReactComponent as IconLiveChat } from "icons/menu/live_chat.svg"
import { ReactComponent as IconProduct } from "icons/menu/product.svg"

export const menuItems = ({ onAccount }: any) => [
  {
    id: "account",
    icon: IconAccount,
    title: "Account",
    linkTo: "/account",
    onClick: onAccount,
    isDivider: true,
  },
  {
    id: "live_chat",
    icon: IconLiveChat,
    title: "Live Chat",
    onClick: () => {
      // @ts-ignore
      const iframeDoc = document.getElementById("chaskiqPrime")?.contentDocument
      if (iframeDoc?.getElementById("chaskiq-prime")) {
        iframeDoc.getElementById("chaskiq-prime").click()
      }
    },
  },
  {
    id: "contact_support",
    icon: IconContactSupport,
    title: "Email Support",
    href: "mailto:support@cience.com?subject=Contact%20Cience%20Support",
    isEmail: true,
  },
  {
    id: "help_center",
    icon: IconHelpCenter,
    title: "Help Center",
    href: "https://helpcenter.cience.com/",
    isDivider: true,
  },
  {
    id: "product_tour",
    icon: IconProduct,
    title: "Product Tour",
    href: "/",
    isDisabled: true,
  },
  {
    id: "changelog",
    icon: IconChangelog,
    title: "Changelog",
    linkTo: "/changelog",
  },
  {
    id: "feedback",
    icon: IconFeedback,
    title: "Feedback",
    linkTo: "/feedback",
    isDivider: true,
  },
  {
    id: "book_call",
    icon: IconBookCall,
    title: "Book a Call",
    linkTo: "/book-a-call",
  },
]
