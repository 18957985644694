// @Libs
import React, { memo } from "react"
import { Tooltip } from "antd"
import cn from "classnames"
// @Icons
import Icon from "@ant-design/icons"
import { ReactComponent as InfoIcon } from "icons/info.svg"

export interface Props {
  render?: React.ReactNode
  documentation: React.ReactNode
  className?: string
}

const LabelWithTooltipComponent = ({ render, documentation, className }: Props) => (
  <span className={cn("label-with-tooltip", className)}>
    <span className="label-with-tooltip_label">{render}&nbsp;</span>
    <Tooltip title={documentation}>
      <Icon component={InfoIcon} size={16} />
    </Tooltip>
  </span>
)

LabelWithTooltipComponent.displayName = "LabelWithTooltip"

export const LabelWithTooltip = memo(LabelWithTooltipComponent)
