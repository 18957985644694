import AvailableLinks from "./goPlatformGetAvailableApps";
import getAvailableOrganizations from "./goPlatformGetOrganizations";
import MenuBuilder from "./goPlatformMenuBuilder";
import { navLinks } from "./goPlatformNavLinks";

let organizationId = null;

async function goPlatformApi(defaultData: { userId: any }) {
  const params = new URL(window.location.href).searchParams;
  const mainOrganization = params.get("organization");

  if (mainOrganization) {
    localStorage.setItem("main_organization", mainOrganization);
  }

  const localMainOrganization = localStorage.getItem("main_organization");

  if (defaultData.userId) {
    if (!localMainOrganization) {
      organizationId = await getAvailableOrganizations(defaultData.userId);
    } else {
      organizationId = localMainOrganization;
    }
  }



  const availableApps = await AvailableLinks(organizationId);
  return MenuBuilder(navLinks, availableApps);
}

export const data = {
  goPlatformApi,
};
